<template>
  <!-- 数字博物馆 -->
  <div class="TopBox">
    <iframe
      width="100%"
      height="100%"
      allowvr="yes"
      allowfullscreen="true"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      oallowfullscreen="true"
      msallowfullscreen="true"
      scrolling="no"
      frameborder="0"
      src="https://720yun.com/vr/de925qOucbn"
    >
    </iframe>
    <div class="BigNavigation">
      <div class="shouzhi" @click="Back()">
        <i
          style="position: absolute;z-index: 200;display: flex;align-items: center;justify-content: center;"
          ><img src="../../assets/VRVisit/Back.png" alt=""
        /></i>
        <div class="back"></div>
        <p class="item">首</p>
        <div class="Navigation "></div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  data() {
    return {};
  },

  created() {},
  methods: {
    Back() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="less" scoped>
iframe {
  width: 100%;
  height: 100%;
  z-index: 1;
}
p {
  color: white;
  text-align: center;
  width: 50%;
  font-size: 14px;
}

img {
  width: 30px;
  height: 30px;
  margin: 10px 10px;
  z-index: 997;
}
* {
  padding: 0;
  margin: 0;
}
.TopBox {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
}
.Navigation {
  opacity: 0.6;
  display: flex;
  background-color: rgb(0, 0, 0);
  z-index: 994;
}
.item {
  font-size: 20px;
  margin: 3px auto;
  position: absolute;
  align-items: center;
  display: none;
  z-index: 999;
}
.back {
  z-index: 998;
  background-color: black;
  opacity: 1.5;
  position: absolute;
  display: none;
}

.item,
.Navigation,
.back {
  width: 31px;
  height: 31px;
  border: 1px none #ddd;
  border-radius: 10px;
  padding: 10px;
}
.BigNavigation div {
  z-index: 999;
  cursor: pointer;
}
.BigNavigation div:hover {
  .item,
  .new,
  .back {
    display: block;
  }
}

.BigNavigation {
  position: fixed;
  right: 19px;
  top: 345px;
}
</style>
